import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, FormLabel, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { GetApp } from "@material-ui/icons";
import { getOptionsTableApp } from "../../helpers/useOption";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";
import { getColumnsTableMenuReport } from "./helpers/optionMenuReport";
import { getMenuOption, getReportMenu } from "../../../apis/reportApi";
import { currencyTHB } from "../../../utils/formatCurrency";
import { swalCondition } from "../../../utils/swal";
import exportToExcel from "../../../utils/exportToExcel";

export default function MenuSummaryContent() {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // component state
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataFilter, setDataFilter] = useState({
        branch: {},
        menu: {},
        skill: {},
        date: [new Date(), new Date()],
        tags: [],
    });
    const [options, setOption] = useState({
        isAdmin: false,
        branchId: 0,
        branchOption: [],
        levelOption: [],
        menuOption: [],
        tagOption: [],
    });

    // function filter
    const handleOnchageFilter = useCallback((value, typeField, indexDate = null) => {
        setDataFilter(prevState => {
            const newDataFilter = {};
            if (typeField === "date" && indexDate !== null) {
                if (value) {
                    newDataFilter.date = prevState.date.map((d, idx) => (idx === indexDate ? value : d));
                    if (indexDate === 0) {
                        if (value.getTime() > prevState.date[1].getTime()) {
                            newDataFilter.date = prevState.date.map((d, idx) => (idx === 1 ? value : d));
                        }
                    }
                }
                return { ...prevState, ...newDataFilter };
            }
            newDataFilter[typeField] = value;
            return { ...prevState, ...newDataFilter };
        });
        // tableRef.current.onQueryChange({ search: "", page: 0 });
    }, []);

    // Export Excel
    const handleExportExcel = useCallback(() => {
        if (dataExcel.length < 1) return false;
        exportToExcel(dataExcel, { fileName: "report-menu", sumColumn: ["Sale"] });
    }, [dataExcel]);
    // end

    // ***
    const fetchOption = useCallback(async () => {
        try {
            const option = await getMenuOption({ type: "1" }, controllerRef?.current?.signal);
            if (option) {
                setOption(option);
                if (!option?.isAdmin) {
                    setDataFilter(state => ({ ...state, branch: { id: option?.branchId } }));
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const body = {
                branchId: dataFilter?.branch?.id,
                menuId: dataFilter?.menu?.id,
                levelId: dataFilter?.skill?.id,
                tagId: dataFilter?.tags?.map(tag => tag?.tag_id),
                startDate: dataFilter?.date[0],
                endDate: dataFilter?.date[1],
            };
            const res = await getReportMenu(body, controllerRef?.current?.signal);
            if (res) {
                if (res?.isBranchActive === false) {
                    return await swalCondition("Your Branch is not active", "Please Contact Admin", {
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                let counter = 0;
                for (let i = 0; i < res?.length; i++) {
                    counter += Number.isNaN(res[i].sale) ? 0 : res[i].sale;
                }
                const data = res?.map(report => ({
                    Branch: report?.branch,
                    Menu: report?.menuName,
                    "Skill Level": report?.level,
                    Tags: report?.tag?.join(", "),
                    Transaction: report?.transaction,
                    Sale: report?.sale,
                }));
                setDataExcel(data);
                setDataTable(res);
                setTotal(counter);
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsLoading(false);
        }
    }, [dataFilter]);

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();

        return () => controllerRef.current?.abort();
    }, [fetchOption]);

    useEffect(() => {
        fetch();
    }, [dataFilter, fetch]);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Branch :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ branch_name: "All" }, ...options?.branchOption]}
                                        value={
                                            options?.branchOption?.find(it => it.id === dataFilter?.branch?.id) || ""
                                        }
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "branch")}
                                        getOptionLabel={option => option?.branch_name}
                                        getOptionValue={option => option?.id}
                                        isDisabled={!options?.isAdmin}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Menu :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ course_menu_name: "All" }, ...options?.menuOption]}
                                        value={options?.menuOption?.find(it => it.id === dataFilter?.menu?.id) || ""}
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "menu")}
                                        getOptionLabel={option => option?.course_menu_name}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Skill Level :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ category_name: "All" }, ...options?.levelOption]}
                                        value={options?.levelOption?.find(it => it.id === dataFilter?.skill?.id) || ""}
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "skill")}
                                        getOptionLabel={option => option.category_name}
                                        getOptionValue={option => option.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Order Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter.date[0]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 0);
                                        }}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        minDate={dataFilter.date[0]}
                                        value={dataFilter.date[1]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 1);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Tags :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchMultiApp
                                        size="sm"
                                        className="fixed-h-lg"
                                        options={options?.tagOption}
                                        value={dataFilter.tags || {}}
                                        onChange={value => handleOnchageFilter(value, "tags")}
                                        getOptionLabel={option => option.tag_name}
                                        getOptionValue={option => option.tag_id}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="d-flex align-items-center mt-lg-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-fill flex-lg-grow-0"
                                        onClick={() => {
                                            setDataFilter(state => ({
                                                branch: options?.isAdmin ? {} : state?.branch,
                                                menu: {},
                                                skill: {},
                                                date: [new Date(), new Date()],
                                                tags: [],
                                            }));
                                            // tableRef.current.onQueryChange({ search: "", page: 0 });
                                        }}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div className="d-flex align-items-center justify-content-lg-end mt-lg-6">
                            <Button
                                size="large"
                                className={`${dataExcel.length < 1 &&
                                    "disabled"} btn t-btn-primary fixed-h-lg flex-fill flex-lg-grow-0`}
                                onClick={handleExportExcel}>
                                <GetApp style={{ fontSize: 22 }} />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={getOptionsTableApp}
                        components={{ Container: Box }}
                        data={dataTable}
                        columns={getColumnsTableMenuReport()}
                    />
                </div>
                <div className="d-flex justify-content-end px-20 mt-10">
                    <div className="d-flex">
                        <p className="mr-5 h5">Grand Total</p>
                        <p className="h5" style={{ textDecoration: "underline" }}>
                            {currencyTHB(total)}
                        </p>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
