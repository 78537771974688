import React, { useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { initialValuesEditOrder, validationSchemaOrder } from "./helpers/orderTransactionOption";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { currencyNoSymbol } from "../../../utils/formatCurrency";
import { getOrdinalSuffix } from "../../../utils/number";

const useStyle = makeStyles(() => ({
    container: {
        "& .print-input .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "#efefef30",
        },
        "& .print-input .MuiInputBase-root.Mui-disabled input": {
            color: "#333",
        },
        "& .print-input .MuiInputBase-input.Mui-disabled": {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

export default function FormEditOrder({
    onSubmit = () => {},
    onCancel = () => {},
    initialFormValues = initialValuesEditOrder,
}) {
    const classes = useStyle();

    // component state
    const [isDeleting, setDeleting] = useState(false);
    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchemaOrder}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, setFieldValue, setFieldError, errors }) => (
                <Grid container spacing={0} style={{ minWidth: 1000 }}>
                    <Grid item xs={12}>
                        <div className={`px-3 px-lg-8 ${classes.container}`}>
                            <FieldArray name="orderLists">
                                {() => (
                                    <table className="table mb-10 FS16PX_P">
                                        <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                                            <tr className="text-center">
                                                <th>No.</th>
                                                <th>Item</th>
                                                <th>Booking Date</th>
                                                <th>Quantity</th>
                                                <th>Unit Price (THB)</th>
                                                <th>Total</th>
                                                {values?.orderLists?.length ? <th>{null}</th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values?.orderLists?.map((row, idx) => {
                                                return (
                                                    <tr key={`list${idx}`}>
                                                        <td className="border text-center">{idx + 1}</td>
                                                        <td className="border text-left">
                                                            <div style={{ minWidth: 200 }}>
                                                                <SelectSearchApp
                                                                    options={values?.orderLists[idx]?.options}
                                                                    value={values?.orderLists[idx]?.value}
                                                                    getOptionLabel={option => option?.label}
                                                                    getOptionValue={option => option?.id}
                                                                    onChange={value => {
                                                                        if (!value) {
                                                                            setFieldError(
                                                                                `orderLists.${idx}.value`,
                                                                                "Required!",
                                                                            );
                                                                        }
                                                                        const price = Number(value?.price);
                                                                        const total = Number(values?.total);
                                                                        const discount = Number(values?.discount);
                                                                        const paid = Number(values?.paid);
                                                                        const newTotal = total - row?.price + price;
                                                                        const grandTotal = newTotal - discount;
                                                                        const remaining = grandTotal - paid;
                                                                        setFieldValue(`orderLists.${idx}.value`, value);
                                                                        setFieldValue(`orderLists.${idx}.price`, price);
                                                                        setFieldValue("total", newTotal);
                                                                        setFieldValue("grandTotal", grandTotal);
                                                                        setFieldValue("remaining", remaining);
                                                                    }}
                                                                    error={
                                                                        errors?.orderLists
                                                                            ? errors?.orderLists[idx]?.value
                                                                            : ""
                                                                    }
                                                                    isDisabled={
                                                                        !values?.orderLists[idx]?.options?.length
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="border text-center">
                                                            {row?.bookingDate
                                                                ? moment(row?.bookingDate).format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="border text-right">{row?.quantity}</td>
                                                        <td className="border text-right">
                                                            {currencyNoSymbol(row?.price || "")}
                                                        </td>
                                                        <td className="border text-right">
                                                            {currencyNoSymbol(row?.price * row?.quantity || "")}
                                                        </td>
                                                        <td className="border text-right">
                                                            <Button
                                                                size="large"
                                                                disabled={isDeleting}
                                                                className="btn t-btn-primary"
                                                                onClick={() => {
                                                                    setDeleting(true);
                                                                    const findOrderList = values?.orderLists?.find(
                                                                        (item, index) => index === idx,
                                                                    );
                                                                    const total = Number(values?.total);
                                                                    const discount = Number(values?.discount);
                                                                    const paid = Number(values?.paid);
                                                                    const newTotal = total - findOrderList?.price;
                                                                    const grandTotal = newTotal - discount;
                                                                    const remaining = grandTotal - paid;
                                                                    setFieldValue("total", newTotal);
                                                                    setFieldValue("grandTotal", grandTotal);
                                                                    setFieldValue("remaining", remaining);
                                                                    setFieldValue(
                                                                        "orderLists",
                                                                        values?.orderLists?.filter(
                                                                            (item, index) => index !== idx,
                                                                        ),
                                                                    );
                                                                    setDeleting(false);
                                                                }}>
                                                                {isDeleting ? "Loading..." : <Delete />}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border text-left" style={{ fontWeight: 800 }}>
                                                    Sub Total
                                                </td>
                                                <td className="border text-right" style={{ fontWeight: 800 }}>
                                                    {currencyNoSymbol(values?.total || "")}
                                                </td>
                                                {values?.orderLists?.length ? <td className="border-0" /> : null}
                                            </tr>
                                            <tr>
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border text-left" style={{ fontWeight: 800 }}>
                                                    Discount
                                                </td>
                                                <td className="border text-right" style={{ fontWeight: 800 }}>
                                                    {currencyNoSymbol(values?.discount || "")}
                                                </td>
                                                {values?.orderLists?.length ? <td className="border-0" /> : null}
                                            </tr>
                                            <tr className="text-center">
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td className="border-0" />
                                                <td
                                                    className="border total-color text-white text-left"
                                                    style={{ backgroundColor: "#b6a68b", fontWeight: 800 }}>
                                                    Grand Total (THB)
                                                </td>
                                                <td
                                                    className="border total-color text-white text-right"
                                                    style={{ backgroundColor: "#b6a68b", fontWeight: 800 }}>
                                                    {currencyNoSymbol(values?.grandTotal || "")}
                                                </td>
                                                {values?.orderLists?.length ? <td className="border-0" /> : null}
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </FieldArray>
                            <table className="table mb-10 FS16PX_P">
                                <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                                    <tr className="text-center">
                                        <th>Payment Date</th>
                                        <th>Amount</th>
                                        <th>Payment Method</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values?.transactions?.map((row, idx) => {
                                        return (
                                            <tr key={`list${idx}`}>
                                                <td className="border text-left">{row?.paymentDate}</td>
                                                <td className="border text-right">{row?.amount}</td>
                                                <td className="border text-left">{row?.paymentMethod}</td>
                                                {idx + 1 === values?.transactions?.length ? (
                                                    <td className="border text-left">
                                                        {parseFloat(values?.remaining || "0.00") !== 0
                                                            ? `${idx + 1}${getOrdinalSuffix(Number(idx + 1))} Payment`
                                                            : "Paid"}
                                                    </td>
                                                ) : (
                                                    <td className="border text-left">{row?.status}</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="border text-left" style={{ fontWeight: 800 }}>
                                            Paid Amount
                                        </td>
                                        <td className="border text-right" style={{ fontWeight: 800 }}>
                                            {currencyNoSymbol(values?.paid || "")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border text-left" style={{ fontWeight: 800 }}>
                                            Amount Due
                                        </td>
                                        <td className="border text-right" style={{ fontWeight: 800 }}>
                                            {currencyNoSymbol(values?.remaining || "")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
