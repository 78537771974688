/* eslint no-unused-vars: */
import React from "react";
import { Chip } from "@material-ui/core";
import { Column } from "material-table";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTableMenuReport = () => {
    /** @type Column */
    const column = [
        {
            field: "branch",
            title: "Branch",
        },
        {
            field: "menuName",
            title: "Menu",
        },
        {
            field: "level",
            title: "Skill Level",
        },
        {
            field: "tag",
            title: "Tags",
            render: rowData => rowData?.tag?.map(t => <Chip key={t} label={t} size="small" />),
        },
        {
            field: "transaction",
            title: "Transaction",
        },
        {
            field: "sale",
            title: "Sales",
            render: rowData => currencyTHB(rowData?.sale),
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
