/* eslint no-unused-vars: */
import React from "react";
import { Chip } from "@material-ui/core";
import { Column } from "material-table";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTableCourseReport = () => {
    /** @type Column */
    const column = [
        {
            field: "branch",
            title: "Branch",
        },
        {
            field: "courseName",
            title: "Course",
        },
        {
            field: "tags",
            title: "Tags",
            render: rowData => rowData?.tag?.map(t => <Chip key={t} label={t} size="small" />),
        },
        {
            field: "transaction",
            title: "Transaction",
        },
        {
            field: "sale",
            title: "Sales",
            render: rowData => currencyTHB(rowData?.sale),
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
