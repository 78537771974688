import React, { useEffect } from "react";
import { FormLabel, makeStyles, TextField } from "@material-ui/core";
// import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import { usePDF } from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { initialReceiptData } from "../OrderTransaction/helpers/orderTransactionOption";

const useStyle = makeStyles(theme => ({
    containerPrint: {
        width: "100%",
        position: "relative",
        [theme?.breakpoints.down("md")]: {
            width: 1300,
        },
        "& .print-input .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "#efefef30",
        },
        "& .print-input .MuiInputBase-root.Mui-disabled input": {
            color: "#333",
        },
        "& .print-input .MuiInputBase-input.Mui-disabled": {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
    cancelled: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99,
        "& > span": {
            textAlign: "center",
            width: "100%",
            transform: "rotate(-45deg)",
            color: "#f4000033",
            fontSize: 150,
            fontWeight: 1000,
        },
    },
}));

export default function ReceiptPDF({
    state = initialReceiptData,
    onError,
    onAfterPrint = () => {},
    onBeforePrint = () => {},
    autoPrint = true,
    handleFinishPDF = () => {},
}) {
    const classes = useStyle();
    const componentPDF = React.useRef(null);

    // const { toPDF, targetRef } = usePDF({ filename: `SCA_${state?.bookingNo}.pdf`, canvas: { useCORS: true } });

    // print receipt
    const handlePrint = useReactToPrint({
        content: () => componentPDF?.current,
        documentTitle: `SCA_${state?.bookingNo}.pdf`,
        bodyClass: "p-15 print-custom h-100 w-100",
        onAfterPrint: () => {
            onAfterPrint();
            // setIsAutoPrint(false);
        },
        onBeforePrint: () => {
            onBeforePrint();
            // setIsAutoPrint(autoPrint);
        },
        onPrintError: onError,
        removeAfterPrint: true,
    });

    useEffect(() => {
        if (componentPDF?.current !== null && autoPrint) {
            handlePrint();
            // toPDF();
            handleFinishPDF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleFinishPDF, autoPrint]);

    return (
        <div className={`container px-3 px-lg-8 ${classes.containerPrint}`} ref={componentPDF}>
            {/* start cancel label */}
            {["2"].includes(state?.status) ? (
                <div className={`${classes.cancelled}`}>
                    <span>CANCELLED</span>
                </div>
            ) : null}
            {/* end cancel label */}
            {/* start refund label */}
            {["4"].includes(state?.status) ? (
                <div className={`${classes.cancelled}`}>
                    <span>REFUNDED</span>
                </div>
            ) : null}
            {/* end refund label */}

            {/* start order detail */}
            <div className="row justify-content-center align-items-center">
                <div className="col-3 d-flex align-items-center justify-content-end h-100">
                    <img
                        src={toAbsoluteUrl("/media/logos/logo-sweetcottage.png")}
                        style={{ height: 150 }}
                        className="img-fluid"
                        alt=""
                    />
                </div>
                <div className="col-7">
                    <h1 className="h1">Sweets Cottage Academy</h1>
                    <p className="mb-2 FS16PX_P">{state?.branchAddress}</p>
                    <p className="FS16PX_P">{state?.branchContact}</p>
                </div>
            </div>
            <h3 className="text-center my-10" style={{ fontWeight: 800 }}>
                Receipt
            </h3>
            <div className="row justify-content-end">
                <div className="col-7" />
                <div className="col-5 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mb-0 text-center FS16PX_P" style={{ width: 150 }}>
                            Date:
                        </FormLabel>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.date}
                        />
                    </div>
                </div>
                <div className="col-7" />
                <div className="col-5 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mb-0 text-center FS16PX_P" style={{ width: 150 }}>
                            Receipt No.
                        </FormLabel>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.bookingNo}
                        />
                    </div>
                </div>

                <div className="col-7 mb-8">
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                Name - Surname
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 150 }}>
                                ชื่อ - นามสกุล
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.studentFullname}
                        />
                    </div>
                </div>
                <div className="col-5 mb-8">
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 text-center FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                Nickname
                            </FormLabel>
                            <FormLabel className="mb-0 text-center FS16PX_P" style={{ width: 150 }}>
                                ชื่อเล่น
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.studentNickname}
                        />
                    </div>
                </div>
                <div className="col-12 mb-8">
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                Telephone
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 150 }}>
                                เบอร์โทร
                            </FormLabel>
                        </div>
                        <div className="row flex-fill">
                            <div className="col-3">
                                <TextField
                                    disabled
                                    className="FS16PX_P print-input border-bottom"
                                    size="small"
                                    variant="standard"
                                    margin="none"
                                    value={state?.studentPhoneNumber}
                                />
                            </div>
                            <div className="col-9">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                        <FormLabel className="mb-2 FS16PX_P" style={{ width: 60, fontWeight: 600 }}>
                                            Email
                                        </FormLabel>
                                        <FormLabel className="mb-0 FS16PX_P" style={{ width: 60 }}>
                                            อีเมล
                                        </FormLabel>
                                    </div>
                                    <TextField
                                        disabled
                                        className="flex-fill FS16PX_P print-input border-bottom"
                                        size="small"
                                        variant="standard"
                                        margin="none"
                                        value={state?.studentEmail}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end order detail */}

            {/* start order list table */}
            <table className="table mb-10 FS16PX_P">
                <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                    <tr className="text-center">
                        <th>No.</th>
                        <th>Item</th>
                        <th>Booking Date</th>
                        <th>Quantity</th>
                        <th>Unit Price (THB)</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {state?.orderList?.map((row, idx) => {
                        return (
                            <tr key={`list${idx}`}>
                                <td className="border text-center">{idx + 1}</td>
                                <td className="border text-left">{row?.name}</td>
                                <td className="border text-center">{row?.bookingDate}</td>
                                <td className="border text-right">{row?.quantity}</td>
                                <td className="border text-right">{row?.price}</td>
                                <td className="border text-right">{row?.total}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border text-left" style={{ fontWeight: 800 }}>
                            Sub Total
                        </td>
                        <td className="border text-right" style={{ fontWeight: 800 }}>
                            {state?.total}
                        </td>
                    </tr>
                    <tr>
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border text-left" style={{ fontWeight: 800 }}>
                            Discount
                        </td>
                        <td className="border text-right" style={{ fontWeight: 800 }}>
                            {state?.discount}
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className="border-0" />
                        <td
                            className="border total-color text-white text-left"
                            style={{ backgroundColor: "#b6a68b", fontWeight: 800 }}>
                            Grand Total (THB)
                        </td>
                        <td
                            className="border total-color text-white text-right"
                            style={{ backgroundColor: "#b6a68b", fontWeight: 800 }}>
                            {state?.grandTotal}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* end order list table */}

            {/* start payment detail */}
            <div className="row">
                <div className="col-12 mb-8">
                    <h5 className="mb-2" style={{ width: "fit-content", fontWeight: 600 }}>
                        Payment Detail
                    </h5>
                    <h5 className="mb-0" style={{ width: "fit-content" }}>
                        ชำระเงินผ่านช่องทาง
                    </h5>
                </div>
                {/* <div className="col-4 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mr-2">
                            {state?.hasCash ? (
                                <CheckCircleOutlineIcon fontSize="large" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="large" />
                            )}
                        </FormLabel>
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 70, fontWeight: 600 }}>
                                Cash
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 70 }}>
                                เงินสด
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.cash}
                        />
                    </div>
                </div> */}
                {/* <div className="col-4 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mr-2">
                            {state?.hasTransfer ? (
                                <CheckCircleOutlineIcon fontSize="large" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="large" />
                            )}
                        </FormLabel>
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 130, fontWeight: 600 }}>
                                Bank Transfer
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 130 }}>
                                โอนเงิน
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.transfer}
                        />
                    </div>
                </div> */}
                {/* <div className="col-4 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mr-2">
                            {state?.hasCreditCard ? (
                                <CheckCircleOutlineIcon fontSize="large" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="large" />
                            )}
                        </FormLabel>
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 120, fontWeight: 600 }}>
                                Credit Card
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 120 }}>
                                บัตรเครดิต
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.creditCard}
                        />
                    </div>
                </div> */}
                {/* <div className="col-4 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mr-2">
                            {state?.hasPackage ? (
                                <CheckCircleOutlineIcon fontSize="large" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="large" />
                            )}
                        </FormLabel>
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 140, fontWeight: 600 }}>
                                Package Credit
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 140 }}>
                                วงเงินเครดิต
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.package}
                        />
                    </div>
                </div> */}
                {/* <div className="col-4 mb-8">
                    <div className="d-flex align-items-center">
                        <FormLabel className="mr-2">
                            {state?.hasOther ? (
                                <CheckCircleOutlineIcon fontSize="large" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="large" />
                            )}
                        </FormLabel>
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 140, fontWeight: 600 }}>
                                Other Payment
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 140 }}>
                                ช่องทางอื่น
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.other}
                        />
                    </div>
                </div> */}
            </div>
            <table className="table mb-10 FS16PX_P">
                <thead className="text-white" style={{ backgroundColor: "#E4B07B" }}>
                    <tr className="text-center">
                        <th>Payment Date</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {state?.transactions?.map((row, idx) => {
                        return (
                            <tr key={`list${idx}`}>
                                <td className="border text-left">{row?.paymentDate}</td>
                                <td className="border text-right">{row?.amount}</td>
                                <td className="border text-left">{row?.paymentMethod}</td>
                                <td className="border text-left">{row?.status}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="border text-left" style={{ fontWeight: 800 }}>
                            Paid Amount
                        </td>
                        <td className="border text-right" style={{ fontWeight: 800 }}>
                            {state?.paid}
                        </td>
                    </tr>
                    <tr>
                        <td className="border text-left" style={{ fontWeight: 800 }}>
                            Amount Due
                        </td>
                        <td className="border text-right" style={{ fontWeight: 800 }}>
                            {state?.remaining}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="row">
                <div className="col-12 mb-8">
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <FormLabel className="mb-2 FS16PX_P" style={{ width: 80, fontWeight: 600 }}>
                                Remark
                            </FormLabel>
                            <FormLabel className="mb-0 FS16PX_P" style={{ width: 80 }}>
                                หมายเหตุ
                            </FormLabel>
                        </div>
                        <TextField
                            disabled
                            className="flex-fill FS16PX_P print-input border-bottom"
                            size="small"
                            variant="standard"
                            margin="none"
                            value={state?.remark}
                        />
                    </div>
                </div>
            </div>
            {/* end payment detail */}

            {/* start refund and cancel */}
            {["2"].includes(state?.status) ? (
                <div className="row">
                    <div className="col-6 mb-8">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <FormLabel className="mb-2 FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                    Order Cancelled
                                </FormLabel>
                                <FormLabel className="mb-0 FS16PX_P" style={{ width: 150 }}>
                                    วันเวลาที่ยกเลิก
                                </FormLabel>
                            </div>
                            <TextField
                                disabled
                                className="flex-fill FS16PX_P print-input border-bottom"
                                size="small"
                                variant="standard"
                                margin="none"
                                value={state?.cancelledDate}
                            />
                        </div>
                    </div>
                    <div className="col-6 mb-8">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <FormLabel className="mb-2 FS16PX_P" style={{ width: 170, fontWeight: 600 }}>
                                    Order Cancelled By
                                </FormLabel>
                                <FormLabel className="mb-0 FS16PX_P" style={{ width: 170 }}>
                                    ผู้ยกเลิกบิล
                                </FormLabel>
                            </div>
                            <TextField
                                disabled
                                className="flex-fill FS16PX_P print-input border-bottom"
                                size="small"
                                variant="standard"
                                margin="none"
                                value={state?.cancelledUser}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
            {["4"].includes(state?.status) ? (
                <>
                    <div className="row">
                        <div className="col-6 mb-8">
                            <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                    <FormLabel className="mb-2 FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                        Refund Amount
                                    </FormLabel>
                                    <FormLabel className="mb-0 FS16PX_P" style={{ width: 150 }}>
                                        จำนวนเงินคืน
                                    </FormLabel>
                                </div>
                                <TextField
                                    disabled
                                    className="flex-fill FS16PX_P print-input border-bottom"
                                    size="small"
                                    variant="standard"
                                    margin="none"
                                    value={state?.refund}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-8">
                            <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                    <FormLabel className="mb-2 FS16PX_P" style={{ width: 150, fontWeight: 600 }}>
                                        Refund Note
                                    </FormLabel>
                                    <FormLabel className="mb-0 FS16PX_P" style={{ width: 150 }}>
                                        หมายเหตุการคืนเงิน
                                    </FormLabel>
                                </div>
                                <TextField
                                    disabled
                                    className="flex-fill FS16PX_P print-input border-bottom"
                                    size="small"
                                    variant="standard"
                                    margin="none"
                                    value={state?.refundNote}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {/* end refund and cancel */}
            <div className="row justify-content-center" style={{ marginTop: 200 }}>
                <div className="col-6">
                    <div className="d-flex flex-column align-content-center text-center">
                        <p className="mb-0 FS16PX_P" style={{ fontWeight: 600 }}>
                            {state?.createdBy}
                        </p>
                        <p className="mb-0 FS16PX_P">
                            ....................................................................
                        </p>
                        <p className="FS16PX_P">ผู้เปิดบิล</p>
                        <p className="mb-0 FS16PX_P">วันที่ ........../........../..........</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex flex-column align-content-center text-center">
                        <p className="mb-0 FS16PX_P" style={{ fontWeight: 600 }}>
                            {state?.collector}
                        </p>
                        <p className="mb-0 FS16PX_P">
                            ....................................................................
                        </p>
                        <p className="FS16PX_P">ผู้รับเงิน</p>
                        <p className="mb-0 FS16PX_P">วันที่ ........../........../..........</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
